import React from 'react';
import { FaNetworkWired, FaCloud, FaTools, FaCogs } from 'react-icons/fa';
import '../../assets/css/Services.css';
import CallToAction from '../CallToActions';

const Services = () => {
  return (
    <div className="services-container">
      <header className="services-header">
        <FaTools className="services-icon" />
        <hr class="hr hr-blurry" />
        <h2 className="services-title">Secteurs de Services</h2>
        <p className="services-subtitle">
        Safari accompagne les entreprises de services dans leur transformation numérique pour répondre aux défis d'un marché en constante évolution.
        </p>
        <img
          className="services-img"
          src="https://img.freepik.com/free-photo/standard-quality-control-concept-m_23-2150041856.jpg?t=st=1734084303~exp=1734087903~hmac=90e76981b073e603c8b679f4902f40086bd89fbc0b7f5cd25ae44a133c6141cd&w=1380"
          alt="Services"
        />
      </header>
      <main className="services-content">
        <section className="services-introduction">
          <h3 className="section-title">Pourquoi choisir nos services ?</h3>
          <p className="services-text">
          Les entreprises de services sont confrontées à des défis technologiques croissants. Nos solutions sont conçues pour vous permettre de :
          </p>
          <ul className="industries-list">
            <li>Optimiser vos processus métier grâce à des solutions numériques avancées.</li>
            <li>Renforcer la sécurité de vos données et infrastructures.</li>
            <li>Adopter des technologies modernes pour rester compétitifs.</li>
          </ul>
        </section>
        <section className="expertise-section">
  <h3 className="section-title">Pourquoi les Secteurs de Services ont besoin d'experts ?</h3>
  <p className="expertise-intro">
    Les secteurs de services, tels que les entreprises financières, logistiques, ou encore de conseil, font face à des défis croissants liés à la transformation numérique. Voici pourquoi ils nécessitent des prestataires spécialisés dans ces domaines :
  </p>
  <div className="expertise-content">
    <div className="expertise-item">
      <FaNetworkWired className="expertise-icon" />
      <div className="expertise-details">
        <h4 className="expertise-title">Infrastructure & Réseaux</h4>
        <p className="expertise-text">
          Un réseau robuste est essentiel pour garantir une connectivité fiable, la continuité des opérations, et la gestion efficace des flux d'informations, particulièrement pour les services nécessitant des communications instantanées.
        </p>
      </div>
    </div>
    <div className="expertise-item">
      <FaCloud className="expertise-icon" />
      <div className="expertise-details">
        <h4 className="expertise-title">Cloud Computing</h4>
        <p className="expertise-text">
          Les solutions cloud offrent une flexibilité inégalée pour les entreprises de services, leur permettant de gérer et d'accéder aux données en toute sécurité, où qu'elles soient.
        </p>
      </div>
    </div>
    <div className="expertise-item">
      <FaCogs className="expertise-icon" />
      <div className="expertise-details">
        <h4 className="expertise-title">Systèmes ERP</h4>
        <p className="expertise-text">
          Les systèmes ERP centralisent les opérations, optimisent les processus internes et améliorent la gestion des ressources. Ils sont particulièrement cruciaux pour les entreprises cherchant à rester compétitives.
        </p>
      </div>
    </div>
  </div>
</section>

        <img
          className="services-img"
          src="https://img.freepik.com/free-photo/business-people-discussing-work-dark-office_74855-2616.jpg?t=st=1734084434~exp=1734088034~hmac=7d089f41c8761fd95741f4049eb713eebe385b3ce5d211ef296f2fa6bbfae63f&w=1380"
          alt="IT Services"
        />
        <section className="services-section">
          <h3 className="section-title mt-5">Nos Engagements</h3>
          <p className="services-text">
            Nous nous engageons à fournir des solutions adaptées aux besoins spécifiques des entreprises de services :
          </p>
          <div className="services-benefits">
            <div className="benefit-card">
              <h4 className="benefit-title">Fiabilité</h4>
              <p className="benefit-text">
                Des services stables et fiables, adaptés à vos besoins spécifiques.
              </p>
            </div>
            <div className="benefit-card">
              <h4 className="benefit-title">Personnalisation</h4>
              <p className="benefit-text">
                Une approche sur mesure pour répondre aux défis uniques de votre entreprise.
              </p>
            </div>
            <div className="benefit-card">
              <h4 className="benefit-title">Support Continu</h4>
              <p className="benefit-text">
                Une assistance dédiée pour garantir le succès de vos projets.
              </p>
            </div>
          </div>
        </section>
      </main>
      < CallToAction text="Prêt à transformer votre entreprise avec nos services ?" />
    </div>
  );
};

export default Services;
