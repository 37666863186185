import React from "react";
import "../../assets/css/cloud.css";
import cloudImage from '../../assets/images/security.jpg'; 
import CallToActions from "../CallToActions";

const ITCloud = () => {
  return (

    <div>
        <div className="itcloud-container" id="cloud">

            <div className="itcloud-content">
            <div className="itcloud-description">
                <h2 data-aos="fade-up">Pourquoi choisir nos services IT & Cloud ?</h2>
                <p data-aos="fade-up">
                Nos experts vous guident dans votre transformation numérique en vous aidant à exploiter les opportunités des technologies IT et Cloud. Nous offrons :
                </p>
                <ul data-aos="fade-right">
                <li>Analyse de vos besoins informatiques actuels.</li>
                <li>Migration vers le Cloud sécurisé.</li>
                <li>Optimisation des infrastructures existantes.</li>
                <li>Formation de vos équipes pour une adoption fluide.</li>
                </ul>
            </div>

            <div className="itcloud-image" data-aos="fade-down">
                <img
                src={cloudImage}
                alt="IT & Cloud Solutions"
                className="responsive-image"
                />
            </div>
            </div>


            <div className="itcloud-details">
            <h2 data-aos="fade-up">Ce que nous proposons</h2>
            <p data-aos="fade-up">
                Nos services couvrent une large gamme d'activités pour vous accompagner dans votre transition numérique :
            </p>
            <ul className="details-list" data-aos="fade-right">
                <li>
                <strong>Stratégie IT :</strong> Élaboration de plans stratégiques pour aligner les technologies sur vos objectifs d'affaires.
                </li>
                <li>
                <strong>Migration Cloud :</strong> Une migration fluide vers des environnements Cloud publics, privés ou hybrides.
                </li>
                <li>
                <strong>Cybersécurité :</strong> Protection de vos systèmes et données grâce à des solutions de sécurité avancées.
                </li>
                <li>
                <strong>Optimisation des coûts :</strong> Identification et réduction des dépenses liées à vos infrastructures IT.
                </li>
            </ul>
            </div>


            <div className="itcloud-advantages">
            <h2 data-aos="fade-up">Nos avantages</h2>
            <div className="row">
                <div className="advantage-card" data-aos="zoom-in">
                <h3>Flexibilité</h3>
                <p>Des solutions adaptées à vos besoins et à votre croissance.</p>
                </div>
                <div className="advantage-card" data-aos="zoom-in">
                <h3>Sécurité</h3>
                <p>Des solutions Cloud et IT robustes pour protéger vos données.</p>
                </div>
                <div className="advantage-card" data-aos="zoom-in">
                <h3>Performance</h3>
                <p>Optimisez vos performances grâce à nos conseils spécialisés.</p>
                </div>
            </div>
            </div>

            
        </div>
        <div className='bg-move contact-btn'>
            <CallToActions text="Vous êtes besoin d'un expert cloud pour votre entreprise ?"/>
        </div>
    </div>
    
  );
};

export default ITCloud;
