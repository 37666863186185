import Footer from "../../components/Footer";
import Header from "../../components/Header";
import SafariTechnologies from "../../components/Structures/SafariTechnologies";


function TechnologiesPage(){
    return(
        <div>
            < Header />
            < SafariTechnologies />
            < Footer />
        </div>
    )
}


export default TechnologiesPage;