import React from 'react';
import { Link } from 'react-router-dom';
import '../../assets/css/Structures.css';
import { FaUsers, FaLightbulb, FaHandshake, FaShieldAlt } from 'react-icons/fa';

const Structures = () => {
  return (
    <div className="structures-container text-center my-5" id="structure">
      <h2 className="structures-title">4 STRUCTURES</h2>
      <div className="row mt-4" data-aos="fade-up">
        <div className="col-md-3 col-sm-6 mb-4">
            <Link to="/structures/technologies" className="structure-card card-3">
              <div className="icon-container">
                <FaHandshake className="structure-icon" />
              </div>
              <h5>SAFARI Technologies</h5>
              <p>
                Offre des <strong>solutions innovantes</strong> pour relever les défis de la{' '}
                <strong>transformation numérique</strong> dans divers domaines.
              </p>
            </Link>
        </div>
        <div className="col-md-3 col-sm-6 mb-4">
          <Link to="/structures/participations" className="structure-card card-1">
            <div className="icon-container">
              <FaUsers className="structure-icon" />
            </div>
            <h5>SAFARI Participations</h5>
            <p>
              Assure la gestion <strong>stratégique et financière</strong> des activités du groupe
              pour un développement harmonieux.
            </p>
          </Link>
        </div>
        <div className="col-md-3 col-sm-6 mb-4">
          <Link to="/structures/conseil" className="structure-card card-2">
            <div className="icon-container">
              <FaLightbulb className="structure-icon" />
            </div>
            <h5 className='text-black'>SAFARI Conseil</h5>
            <p className='text-black'>
              Accompagne dans l’optimisation et la modernisation de vos{' '}
              <strong>infrastructures télécom.</strong>
            </p>
          </Link>
        </div>
        
        <div className="col-md-3 col-sm-6 mb-4">
          <Link to="/structures/securite" className="structure-card card-4">
            <div className="icon-container">
              <FaShieldAlt className="structure-icon" />
            </div>
            <h5>SAFARI & Sécurité</h5>
            <p>
              Garantit des <strong>systèmes digitaux</strong> performants et <strong>sécurisés</strong>{' '}
              grâce à une expertise de pointe.
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Structures;
