import React from 'react';

import '../../assets/css/Criteria.css';
import 'bootstrap/dist/css/bootstrap.min.css';


const Criteria = () => {

    return (
        <div className="four-buttons-section">
        <div className="row text-center w-100" data-aos="fade-up">
          <div className="col-md-3 col-6 mb-3">
            <div className="info-card">
              <h5>120+</h5>
              <p>Collaborateurs</p>
            </div>
          </div>
          <div className="col-md-3 col-6 mb-3">
            <div className="info-card">
              <h5>+6M d’euros</h5>
              <p>Chiffre d’affaires</p>
            </div>
          </div>
          <div className="col-md-3 col-6 mb-3">
            <div className="info-card">
              <h5>100+</h5>
              <p>Projets Différents</p>
            </div>
          </div>
          <div className="col-md-3 col-6 mb-3">
            <div className="info-card">
              <h5>Partenariats</h5>
              <p>Stratégiques</p>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Criteria;