import React from "react";
import CeoMessage from "../components/CeoMessage";
import Header from "../components/Header";
import Footer from "../components/Footer"

function CeoPage(){
    return(
        <div>
            < Header />
            < CeoMessage/>
            < Footer/>
        </div>
    );
}

export default CeoPage;