import React, {useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import  'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ContactPage from './pages/ContactPage';
import ApplicationPage from './pages/solutions/ApplicationsPage';
import ScrollToTop from './components/ScrollToTop';
import CeoPage from './pages/CeoPage';
import AOS from 'aos';
import 'aos/dist/aos.css';
import NetworkPage from './pages/solutions/NetworkPage';
import SystemsPage from './pages/solutions/SystemsPage';
import ITCloudPage from './pages/solutions/ITCloudPage';
import TermsCond from './pages/TermsCond';
import CookieBanner from './components/Cookies/CookieBanner';
import CookiePolicy from './pages/CookiePolicy';
import useAnalytics from './hooks/useAnalytics';
import ScrollToId from './hooks/ScrollToId';
import IndustriesPage from './pages/secteurs/IndustriesPage';
import BankingPage from './pages/secteurs/BankingPage';
import ServicesPage from './pages/secteurs/ServicesPage';
import ParticipationsPage from './pages/structures/ParticipationsPage';
import ConseilsPage from './pages/structures/ConseilsPage';
import TechnologiesPage from './pages/structures/TechnologiesPage';
import SecuritePage from './pages/structures/SecuritePage';

function App() {

  useEffect(() => {
    AOS.init({
      duration: 700,
      once: true,    
    });
  }, []);

  useAnalytics();

  return (
    <Router>
      <ScrollToTop/>
      <ScrollToId />
      <CookieBanner />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/CeoMessage" element={<CeoPage/>}/>

        {/*Solutions*/}
        <Route path="/solutions/ApplicationsERP" element={<ApplicationPage/>}/>
        <Route path="/solutions/Reseau" element={<NetworkPage />}/>
        <Route path="/solutions/Systems" element={<SystemsPage/>}/>
        <Route path="/solutions/ITCloud" element={<ITCloudPage/>}/>
        <Route path="/CGU" element={<TermsCond />}/>
        <Route path="/Politique-cookies" element={<CookiePolicy />}/>

        {/*Sectors*/}
        <Route path="/secteurs/industries" element={<IndustriesPage/>} />
        <Route path="/secteurs/bancaire" element={<BankingPage/>} />
        <Route path="/secteurs/services" element={<ServicesPage/>} />

        {/*Structures*/}
        <Route path="/structures/participations" element={<ParticipationsPage/>} />
        <Route path="/structures/conseil" element={<ConseilsPage/>} />
        <Route path="/structures/technologies" element={<TechnologiesPage/>} />
        <Route path="/structures/securite" element={<SecuritePage/>} />






      </Routes>
    </Router>
  );
}

export default App;