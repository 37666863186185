import React from "react";
import "../assets/css/TermsCond.css";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";

const TermsCond = () => {
    return(
        <div>
            <Header />

            <div className="terms-container">
                <h1 className="terms-title">Conditions Générales d'Utilisation</h1>
                <div className="terms-content">
                    <section>
                        <h2>1. Préambule</h2>
                        <p>
                            Les présentes conditions générales d'utilisation (CGU) régissent l'accès et l'utilisation du site web de Safari Technologies
                            . En accédant au Site, vous acceptez les présentes CGU sans réserve.
                        </p>
                    </section>


                    <section>
                        <h2>2. Accès au Site</h2>
                        <p>
                            Le site est accessible gratuitement à tout utilisateur disposant d'un accès à Internet. Safari Technologies 
                            met tout en oeuvre pour assurer la disponibilité du Site, mais ne peut être tenu responsable des interruptions, quelles
                            qu'en soient la  duréeou la nature.
                        </p>
                    </section>

                    <section>
                        <h2>3. Propriété intellectuelle</h2>
                        <p>
                            Tous les contenus présents sur le Site, incluant les textes, images, logos, vidéos, et graphismes, sont la propriété exclusive
                            de Safari Technologies ou de ses partenaires. Toute reproduction ou utilisation non autorisée est strictement interdite.
                        </p>
                    </section>

                    <section>
                        <h2>4. Données personnelles</h2>
                        <p>
                            Safari Technologies collecte et traite vos données personnelles conformément à sa politique de confidentialité. En utilisant
                            le Site, vous acceptez que vos données soient utilisées conformément à cette politique.
                        </p>
                    </section>

                    <section>
                        <h2>5. Utilisation du Site</h2>
                        <p>
                            Vous vous engagez à utiliser le Site conformément à la loi et aux présentes CGU. Toute utilisation abusive, telle que le piratage,
                            la diffusion de contenus illicites, ou tout comportement nuisible, est strictement interdite.
                        </p>
                    </section>

                    <section>
                        <h2>6. Responsabilités</h2>
                        <p>
                            Safari Technologies ne peut être tenu responsable des dommages directs ou indirects résultant de l'utilisation du Site,
                            y compris les erreurs, omissions ou dysfonctionnements.
                        </p>
                    </section>


                    <section>
                        <h2>7. Modification des CGU</h2>
                        <p>
                            Safari Technologies se réserve le droit de modifier les présentes CGU à tout moment. Les utilisateurs sont invités à
                            consulter régulièrement cette page pour prendre connaissance des éventuelles mises à jour.
                        </p>
                    </section>

                    <section>
                        <h2>8. Loi applicable et juridiction</h2>
                        <p>
                            Les présentes CGU sont régies par la loi française. En cas de litige, seuls les tribunaux français seront compétents.
                        </p>
                    </section>

                    <section>
                        <h2>9. Contact</h2>
                        <p>
                            Pour toute question concernant les présentes CGU, vous pouvez nous contacter à l'adresse suivante :{" "} 
                            <a className="text-beige" href="mailto:contact@safari-technologies.com">contact@safari-technologies.com</a>.
                            ou via le formulaire de contact <Link to="/contact">ici</Link>
                        </p>
                    </section>
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default TermsCond