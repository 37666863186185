import React from "react";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";


const CookieBanner = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Accepter"
      declineButtonText="Refuser"
      enableDeclineButton
      cookieName="userConsent"
      style={{ background: "#2B373B", color: "#fff" }}
      buttonStyle={{
        backgroundColor: "#4CAF50",
        color: "#fff",
        fontSize: "13px",
        borderRadius: "5px",
      }}
      declineButtonStyle={{
        backgroundColor: "#f44336",
        color: "#fff",
        fontSize: "13px",
        borderRadius: "5px",
      }}
    >
      Ce site utilise des cookies pour améliorer votre expérience.{" "}
      <Link to="/Politique-cookies" style={{ color: "#4CAF50", textDecoration: "underline" }}>
        En savoir plus
      </Link>
    </CookieConsent>
  );
};

export default CookieBanner;
