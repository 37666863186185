import React from 'react';
import '../../assets/css/StructuresPages.css';
import { FaLightbulb, FaNetworkWired, FaTools } from 'react-icons/fa';
import CallToAction from '../CallToActions';

const SafariConseil = () => {
  return (
    <div className="participations-container">
      <section className="hero-section-conseil">
        <div className="hero-content">
          <h1 className="hero-title">Safari Conseil</h1>
          <p className="hero-subtitle">
            Offrir des conseils stratégiques pour optimiser vos infrastructures télécom et technologies.
          </p>
        </div>
        <div className="hero-overlay"></div>
      </section>

      <section className="about-section">
        <h2 className="section-title">Qui Sommes-Nous ?</h2>
        <p className="about-text">
          <strong>Safari Conseil</strong> est un partenaire clé pour les entreprises souhaitant moderniser leurs infrastructures et améliorer leur performance technologique. Nous proposons des solutions sur mesure pour accompagner nos clients dans l’optimisation, la planification et l’intégration de leurs systèmes.
        </p>
        <p className="about-text">
          Grâce à notre expertise en technologies de pointe et à notre approche personnalisée, nous aidons nos clients à relever leurs défis opérationnels et à saisir de nouvelles opportunités. <strong>Notre mission</strong> est de fournir des conseils pratiques et efficaces qui transforment vos objectifs en résultats concrets.
        </p>
      </section>

      <section className="process-timeline-section">
        <h2 className="section-title">Notre Approche</h2>
        <div className="timeline">
          <div className="timeline-step">
            <div className="icon-circle">
              <FaLightbulb />
            </div>
            <div className="step-content">
              <h3 className="step-title">Analyse</h3>
              <p>
                Évaluation approfondie de vos infrastructures actuelles pour identifier les opportunités d’amélioration.
              </p>
            </div>
          </div>
          <div className="timeline-step">
            <div className="icon-circle">
              <FaNetworkWired />
            </div>
            <div className="step-content">
              <h3 className="step-title">Planification</h3>
              <p>
                Élaboration de stratégies sur mesure pour optimiser vos performances technologiques.
              </p>
            </div>
          </div>
          <div className="timeline-step">
            <div className="icon-circle">
              <FaTools />
            </div>
            <div className="step-content">
              <h3 className="step-title">Mise en œuvre</h3>
              <p>
                Accompagnement dans l'intégration et la modernisation de vos infrastructures avec des solutions pratiques.
              </p>
            </div>
          </div>
        </div>
        <div className="img-div mt-5">
            <img  className='w-100' height={600} src="https://img.freepik.com/free-photo/operation-procedure-action-ideas-goal-concept_53876-13715.jpg?t=st=1734099960~exp=1734103560~hmac=ba04aeff7b6a1f9a2b2904ccf2e9edd104809b28206c3f8ace1a9af05876c49c&w=1380" alt="Safari Participations Process"></img>
        </div>
      </section>

      <section className="testimonials-section">
        <h2 className="section-title">Ce que disent nos clients</h2>
        <div className="testimonials">
          <blockquote>
            <p>
              "Safari Conseil a joué un rôle clé dans la transformation de nos infrastructures télécom. Leur expertise est
              incomparable."
            </p>
            <footer>— Directeur IT</footer>
          </blockquote>
          <blockquote>
            <p>
              "Les solutions proposées par Safari Conseil nous ont permis d’améliorer considérablement notre efficacité
              opérationnelle."
            </p>
            <footer>— Responsable des Opérations</footer>
          </blockquote>
        </div>
      </section>

      <div className="bg-move contact-btn text-center mt-5">
        <CallToAction text="Contactez Safari Conseil pour des solutions adaptées à vos besoins en infrastructures et technologies." />
      </div>
    </div>
  );
};

export default SafariConseil;
