import React from 'react';
import { FaNetworkWired, FaCloud, FaShieldAlt, FaUniversity } from 'react-icons/fa';
import '../../assets/css/Banking.css';
import CallToAction from '../CallToActions';

const Banking = () => {
  return (
    <div className="banking-container">
      <header className="banking-header">
        <FaUniversity className="banking-icon" />
        <hr class="hr hr-blurry" />
        <h2 className="banking-title">Nos Solutions pour le Secteur Bancaire</h2>
        <p className="banking-subtitle">
          Safari propose des solutions IT adaptées aux banques et institutions financières pour relever les défis numériques et réglementaires.
        </p>
        <img
          className="banking-img"
          src="https://img.freepik.com/free-photo/receptionist-elegant-suit-work-with-customer_23-2149714430.jpg?t=st=1734083095~exp=1734086695~hmac=7610fa6f19f0fdb9deeabafe6430e3f503b8a48961ac3624e2748d7abd301317&w=1380"
          alt="Banking Solutions"
        />
      </header>
      <main className="banking-content">
        <section className="banking-introduction">
          <h3 className="section-title">Pourquoi les solutions IT sont essentielles pour le secteur bancaire ?</h3>
          <p className="banking-text">
            Les institutions financières doivent constamment s'adapter à un environnement technologique en rapide évolution. Les solutions IT jouent un rôle crucial pour :
          </p>
          <ul className="banking-list">
            <li>Renforcer la sécurité des données sensibles et prévenir les cyberattaques.</li>
            <li>Faciliter la conformité avec les réglementations financières.</li>
            <li>Améliorer l'expérience utilisateur grâce à des outils numériques performants.</li>
          </ul>
        </section>

        <section className="banking-expertise-section">
          <h3 className="expertise-section-title">Nos Domaines d'Expertise</h3>
          <p className="expertise-intro-text">
            Dans le secteur bancaire, Safari & Sécurité met à votre disposition des solutions adaptées pour relever les défis technologiques actuels et futurs.
          </p>
          <div className="expertise-timeline">
            <div className="expertise-timeline-item">
              <div className="expertise-icon">
                <FaNetworkWired />
              </div>
              <div className="expertise-content">
                <h4 className="expertise-title">Infrastructure & Réseaux</h4>
                <p className="expertise-description">
                  Déploiement et gestion de réseaux sécurisés pour garantir la continuité et la fiabilité des services bancaires.
                </p>
              </div>
            </div>
            <div className="expertise-timeline-item">
              <div className="expertise-icon">
                <FaCloud />
              </div>
              <div className="expertise-content">
                <h4 className="expertise-title">Cloud Computing</h4>
                <p className="expertise-description">
                  Solutions cloud pour une gestion flexible et sécurisée des données bancaires.
                </p>
              </div>
            </div>
            <div className="expertise-timeline-item">
              <div className="expertise-icon">
                <FaShieldAlt />
              </div>
              <div className="expertise-content">
                <h4 className="expertise-title">Cybersécurité</h4>
                <p className="expertise-description">
                  Protégez vos données sensibles grâce à des systèmes avancés de prévention et de détection des cybermenaces.
                </p>
              </div>
            </div>
          </div>
        </section>


        <img
          className="banking-img"
          src="https://img.freepik.com/free-photo/fintech-investment-financial-internet-technology-concept_53876-124767.jpg?t=st=1734083138~exp=1734086738~hmac=f062d35e00d8e5504afbb641fc5986df47f505dffb5baee4a49a695b3294ad39&w=1480"
          alt="Banking Infrastructure"
        />
        <section className="banking-section">
          <h3 className="section-title mt-5">Nos Engagements pour le Secteur Bancaire</h3>
          <p className="banking-text">
            Chez Safari, nous comprenons les défis uniques du secteur bancaire. Nos engagements reposent sur :
          </p>
          <div className="banking-benefits">
            <div className="benefit-card">
              <h4 className="benefit-title">Sécurité et Fiabilité</h4>
              <p className="benefit-text">
                Garantir la sécurité et la continuité de vos opérations bancaires grâce à des infrastructures robustes.
              </p>
            </div>
            <div className="benefit-card">
              <h4 className="benefit-title">Conformité Réglementaire</h4>
              <p className="benefit-text">
                Des solutions adaptées pour vous conformer facilement aux réglementations locales et internationales.
              </p>
            </div>
            <div className="benefit-card">
              <h4 className="benefit-title">Expérience Client Optimisée</h4>
              <p className="benefit-text">
                Améliorer la satisfaction client grâce à des outils numériques innovants et performants.
              </p>
            </div>
          </div>
        </section>
      </main>
      < CallToAction text="Prêt à révolutionner vos services bancaires ?" />
    </div>
  );
};

export default Banking;
