import { useEffect } from "react";

const useAnalytics = () => {
  useEffect(() => {
    const userConsent = document.cookie.includes("userConsent=true");

    if (userConsent) {
      const script = document.createElement("script");
      script.src = "https://www.googletagmanager.com/gtag/js?id=G-5KMRRTXP43";
      script.async = true;
      document.body.appendChild(script);

      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "G-5KMRRTXP43");
    }
  }, []);
};

export default useAnalytics;
