import Footer from "../../components/Footer";
import Header from "../../components/Header";
import SafariConseil from "../../components/Structures/SafariConseil";

function ConseilsPage(){
    return(
        <div>
            < Header />
            < SafariConseil />
            < Footer />
        </div>
    )
}


export default ConseilsPage;