import React, { useRef, useState} from 'react';
import '../assets/css/contact.css';
import emailjs from '@emailjs/browser';
import { Modal, Button } from 'react-bootstrap';

const Contact = () => {
  const form = useRef();
  const [showSuccess, setShowSuccess] = useState(false); // Success modal
  const [showError, setShowError] = useState(false); // Error modal

  const handleCloseSuccess = () => setShowSuccess(false);
  const handleCloseError = () => setShowError(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_4o7flxd', 'template_bzo9fd8', form.current, {
        publicKey: '2j9_xo0K7fvsuDvxJ',
      })
      .then(
        () => {
          setShowSuccess(true); // Show success modal
          form.current.reset(); // Reset form fields
          console.log('message sent');
        },
        (error) => {
          console.error('Failed to send message:', error.text);
          setShowError(true); // Show error modal
        },
      );
  };

  return (
    <div className="contact-form-container">
      <h2 className="text-center mb-4">Contactez-nous</h2>
      <form ref={form} className="contact-form" onSubmit={sendEmail}>
        <div className="form-group">
          <label htmlFor="nom">Nom</label>
          <input
            type="text"
            id="nom"
            name="from_name"
            className="form-control"
            placeholder="Votre nom"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="entreprise">Entreprise</label>
          <input
            type="text"
            id="entreprise"
            name="entreprise"
            className="form-control"
            placeholder="Votre entreprise"
          />
        </div>

        <div className="form-group">
          <label htmlFor="telephone">Téléphone</label>
          <input
            type="tel"
            id="telephone"
            name="telephone"
            className="form-control"
            placeholder="Votre numéro de téléphone"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="from_email"
            className="form-control"
            placeholder="Votre email"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            name="message"
            className="form-control"
            placeholder="Votre message"
            rows="5"
            required
          ></textarea>
        </div>

        <button type="submit" value="Send" className="btn btn-primary w-100 mt-3">
          Envoyer
        </button>
      </form>

      <Modal show={showSuccess} onHide={handleCloseSuccess}>
        <Modal.Header closeButton>
          <Modal.Title>Succès</Modal.Title>
        </Modal.Header>
        <Modal.Body>Votre message a été envoyé avec succès !</Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleCloseSuccess}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Error Modal */}
      <Modal show={showError} onHide={handleCloseError}>
        <Modal.Header closeButton>
          <Modal.Title>Erreur</Modal.Title>
        </Modal.Header>
        <Modal.Body>Échec de l'envoi du message. Veuillez réessayer plus tard.</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleCloseError}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};


export default Contact;