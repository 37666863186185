import React from 'react';
import '../../assets/css/Systems.css';
import CallToActions from '../CallToActions';
import img from "../../assets/images/c1.jpg"


const Systems = () => {
  return (
    <div>
        <div className="systems-container">
            <h1 className="systems-title" data-aos="fade-down">
                Practice Systèmes
            </h1>
            <div className="systems-content">
                <div className="systems-intro" data-aos="zoom-in">
                <p>
                    Les métiers du système sont donc chargés de la mise en place, de
                    l'intégration et de la maintenance des systèmes composant les solutions
                    des entreprises. On parle ici de compétences de type système
                    d’exploitation, et d’autres services nécessaires au bon fonctionnement
                    des environnements de production.
                </p>
                <p>
                    Nos équipes travaillent pour garantir des systèmes robustes, performants et sécurisés, afin de répondre
                    aux besoins stratégiques et opérationnels de nos clients.
                </p>
                </div>

                <div className="systems-list" data-aos="fade-up">
                <h2 className='systems-card'>Nos Experts</h2>
                <ul>
                    <li>Des ingénieurs</li>
                    <li>Des administrateurs</li>
                    <li>Des chefs de projets</li>
                    <li>Des architectes</li>
                    <li>Des techniciens</li>
                </ul>
                <p>
                    Chaque expert est sélectionné minutieusement pour ses compétences techniques et humaines. Nous croyons
                    fermement qu'une équipe bien formée et motivée est la clé du succès de vos projets informatiques.
                </p>
                </div>

                <img src={img} alt="system" className='w-100 banking-img'/>
                <div className="systems-technologies" data-aos="fade-down">
                <h2>Technologies Utilisées</h2>
                <p>
                    Cette practice concentre les technologies suivantes :
                </p>
                <ul>
                    <li>Microsoft Windows Server</li>
                    <li>Linux et Unix</li>
                    <li>Citrix</li>
                    <li>SCCM et SCOM</li>
                    <li>Control M et VTOM</li>
                </ul>
                <p>
                    Ces outils permettent d'assurer des services fiables, modernes et adaptés aux besoins évolutifs des entreprises.
                </p>
                </div>

                <div className="systems-table" data-aos="fade-down">
                    <table>
                        <thead>
                        <tr>
                            <th>Technologie</th>
                            <th>Usage</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Microsoft SCCM</td>
                            <td>Gestion des configurations</td>
                        </tr>
                        <tr>
                            <td>Linux</td>
                            <td>Serveurs et infrastructures</td>
                        </tr>
                        <tr>
                            <td>Control M</td>
                            <td>Gestion des flux</td>
                        </tr>
                        </tbody>
                    </table>
                </div>


                <div className="systems-values" data-aos="zoom-in">
                <h2>Notre Valeur Ajoutée</h2>
                <p>
                    Nous sommes fiers de fournir des solutions sur mesure, adaptées aux besoins spécifiques de chaque client.
                    Nos services vont au-delà de la simple mise en œuvre technique, en offrant :
                </p>
                <ul>
                    <li>Des audits complets pour évaluer vos besoins actuels.</li>
                    <li>Une stratégie d'intégration alignée sur vos objectifs.</li>
                    <li>Un support technique continu pour garantir le bon fonctionnement de vos systèmes.</li>
                </ul>
                <p>
                    Nous croyons en une collaboration étroite avec nos clients pour maximiser l'efficacité et minimiser les coûts.
                </p>
                </div>
            </div>
        </div>

            
        <div className='bg-move contact-btn'>
            <CallToActions text="Vous êtes besoin d'un expert systèmes pour votre entreprise ?"/>
        </div>
    </div>
    
  );
};

export default Systems;
