import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import  'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Header from '../../components/Header';
import ERPSection from '../../components/ERP/ERPSection';
import Footer from '../../components/Footer';
import MainSection from '../../components/MainSection';
import img from "../../assets/images/s1.jpg"

function ApplicationPage(){

    return(
        <div>
            < Header />
            < MainSection title="Développement Solution ERP/CRM Sur Mesure" text="Explorez les avantages de notre service de développement de solutions ERP/CRM entièrement personnalisées. 
                    Nous comprenons que chaque entreprise est unique, c'est pourquoi nous vous offrons la possibilité de créer un CRM sur mesure."
                    btn="Faites appel à nos consultants" backgroundImage={img}/>
            < ERPSection />
            < Footer />
        </div>
    );
}


export default ApplicationPage;