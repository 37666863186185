import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Banking from "../../components/Sectors/Bancaires";


function BankingPage(){
    return(
        <div>
            < Header />
            < Banking />
            < Footer />
        </div>
    )
}


export default BankingPage;