import React from 'react';
import '../../assets/css/Definition.css';
import illustration from '../../assets/images/pic3.png'; 

const Definition = () => {
  return (
    <div className="definition-container" id="definition">
      <div className="definition-header">
        <button className="definition-title" data-aos="fade-up">À Propos de Nous</button>
      </div>
      <div className="definition-content">
        <img src={illustration} alt="Illustration" className="definition-image" data-aos="fade-right"/>
        <div className="definition-text" data-aos="fade-up">
          <p><strong>SAFARI TECHNOLOGIES</strong> est une <strong>ESN</strong> (Entreprise de Services du numérique) fondée en <strong>2012</strong>.</p>
          <p>
            Notre métier est de <strong>concevoir</strong>, <strong>bâtir</strong> et <strong>commercialiser</strong> des prestations de qualité dans les domaines des technologies
            de l’information et de la communication. Dans ce but, nous avons créé et développé une entreprise de service du numérique.
          </p>
          <p>Avec plus de <strong>10 ans</strong> d'expertise, SAFARI TECHNOLOGIES s'impose comme un <strong>partenaire stratégique</strong> dans la transformation digitale des organisations. 
            Nous concevons, construisons et commercialisons <strong>des prestations</strong> de haute qualité dans les domaines des technologies de l'information et de la communication, 
            en mettant un point d'honneur à dépasser les attentes de nos clients.</p>
        </div>
      </div>
    </div>
  );
};

export default Definition;
