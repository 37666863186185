import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

const CookiePolicy = () => {
  return (
    <div>
        <Header /> 
        <div className="container p-5">
            <h1>Politique de Cookies</h1>
            <p>
                Ce site utilise des cookies pour améliorer l'expérience utilisateur et analyser le trafic. Voici les types de cookies que nous utilisons :
            </p>
            <ul>
                <li><strong>Essentiels :</strong> Nécessaires au bon fonctionnement du site.</li>
                <li><strong>Analyse :</strong> Utilisés pour suivre les performances et le trafic.</li>
                <li><strong>Publicité :</strong> Utilisés pour afficher des publicités personnalisées.</li>
            </ul>
            <p>Vous pouvez modifier vos préférences ou refuser certains cookies depuis les paramètres de votre navigateur.</p>
        </div>
        <Footer />
    </div>
    
  );
};

export default CookiePolicy;
