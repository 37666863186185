import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper'; 
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import '../../assets/css/Swiper.css';

const CompanySwiper = () => {
  return (
    <div className="company-swiper-container">
      <h2 className="swiper-title mb-5">NOS PARTENAIRES</h2>
      <Swiper
        slidesPerView={6}
        spaceBetween={40}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        //pagination={{ clickable: true }}
        navigation={true}
        loop={true}
        modules={[Autoplay, Pagination, Navigation]}
        breakpoints={{
          1024: {
            slidesPerView: 8, // 4 slides on tablets
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 3, // 3 slides on small tablets
            spaceBetween: 20,
          },
          480: {
            slidesPerView: 2, // 2 slides on smartphones
            spaceBetween: 10,
          },
          320: {
            slidesPerView: 2, // 1 slide on smartphones
            spaceBetween: 10,
          },
        }}
        className="mySwiper"
        data-aos="fade-up"
      >
        <SwiperSlide>
          <img src="https://upload.wikimedia.org/wikipedia/commons/5/53/Fujitsu-Logo.svg" width={100} alt="Company 1" className="company-logo mt-2" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="https://www.systancia.com/wp-content/uploads/2022/06/logoapixitwr-1024x512.jpg" alt="Company 2" className="company-logo " />
        </SwiperSlide>
        <SwiperSlide>
          <img src="https://www.hitechpros.com/wp-content/uploads/2024/09/Hitechpros-Logo-Final-AI-2000-pixels.jpg" alt="Company 3" className="company-logo mt-3" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="https://www.tanium.fr/wp-content/uploads/computacenter-logo.png" width={100} alt="Company 4" className="company-logo" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="https://upload.wikimedia.org/wikipedia/fr/thumb/c/cc/Spie.svg/1200px-Spie.svg.png" width={100} alt="Company 5" className="company-logo" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="https://sorbonnejuniorconseil.com/wp-content/uploads/2020/10/AERO20190725124819.png" alt="Company 6" className="company-logo" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="https://neurones-it.com/wp-content/uploads/2023/05/logo-neurones-it.png" alt="Company 7" className="company-logo" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="https://cdn.prod.website-files.com/62c5bdebcf66b43807f181b5/658550df3a2144cff938a659_logo%20rhon%20telecom.webp" width={100} alt="Company 8" className="company-logo mt-2" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="https://connect3c.fr/wp-content/themes/c3c/theme/ressource/Logo_horizontal_blanco.png" alt="Company 9" className="company-logo" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR8ciVvit5AP0qReCIGBVQGtnDot1dENjIbfQ&s" alt="Company 10" className="company-logo" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="https://www.inetum.com/themes/custom/web_ui/img/logo-inetum-social.jpg" alt="Company 11" className="company-logo" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="https://www.cockpit-itsm.com/img/clients/client-6.png" alt="Company 12" className="company-logo" />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default CompanySwiper;
