import React from "react";
import '../assets/css/App.css'
import { Link } from "react-router-dom";


const MainSection = ({title, text, btn, backgroundImage}) => {

    const handleScroll = () => {
        window.scrollBy({
          top: window.innerHeight,
          behavior: "smooth", 
        });
      };

    return (
        <div className="main-container" style={{"--bg-image": `url(${backgroundImage})`}}>
            <div className="main-content">
                <h1 className="main-title" data-aos="fade-down">{title}</h1>
                <p className="main-parag" data-aos="fade-left">{text}</p>
                <Link to="/contact" className="main-button" data-aos="fade-up">
                    {btn}
                </Link>
            </div>
            <div className="arrow-container" onClick={handleScroll}>
                <div className="arrow"></div>
            </div>
        </div>
    );
}

export default MainSection;