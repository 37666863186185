import React from 'react';
import Header from '../components/Header';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import  'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@fortawesome/fontawesome-free/css/all.min.css';

function ContactPage() {
  return (
    <div className="App">
      <Header />
      <Contact />
      <Footer />
    </div>
  );
}

export default ContactPage;
