import React from 'react';
import '../../assets/css/Sectors.css';
import { FaBroadcastTower, FaIndustry, FaMoneyBill, FaUserFriends, FaShieldAlt, FaNetworkWired, FaCogs, FaSatelliteDish } from 'react-icons/fa';
import { Link } from 'react-router-dom';


const Sectors = () => {
  return (
    <div className="sectors-container text-center">
      <h2 className="sectors-title mb-5">NOS SECTEURS D’ACTIVITÉS</h2>
      <div className="row justify-content-center" data-aos="fade-down">
        
        {/* Cards */}
        

        <div className="col-md-3 col-sm-6 mb-4">
          <div className="sector-card sector-4">
            <FaNetworkWired className="sector-icon" />
            <a className='text-decoration-none' href="/solutions/Reseau#p1"><h5 className='text-white'>Réseau</h5></a>
          </div>
        </div>


        <div className="col-md-3 col-sm-6 mb-4">
          <div className="sector-card sector-2">
            <FaShieldAlt className="sector-icon" />
            <a className='text-decoration-none' href="/solutions/Reseau#p2"><h5 className='text-white'>Sécurité</h5></a>
          </div>
        </div>

        

        <div className="col-md-3 col-sm-6 mb-4">
          <div className="sector-card sector-8">
            <FaSatelliteDish className="sector-icon" />
            <Link className='text-decoration-none' to="/solutions/ApplicationsERP#erp"><h5 className='text-white'>ERP/CRM</h5></Link>
          </div>
        </div>

        

        <div className="col-md-3 col-sm-6 mb-4 ">
          <div className="sector-card sector-1">
            <FaBroadcastTower className="sector-icon" />
            <Link className='text-decoration-none' to="/solutions/ITCloud#cloud"><h5 className='text-white'>Cloud</h5></Link>
          </div>
        </div>

        <div className="col-md-3 col-sm-6 mb-4">
          <div className="sector-card sector-3">
            <FaIndustry className="sector-icon" />
            <Link className='text-decoration-none' to="/secteurs/industries"><h5 className='text-white'>Industries</h5></Link>
          </div>
        </div>

        <div className="col-md-3 col-sm-6 mb-4">
          <div className="sector-card sector-5">
            <FaMoneyBill className="sector-icon" />
            <Link className='text-decoration-none' to="/secteurs/bancaire"><h5 className='text-white'>Bancaire</h5></Link>
          </div>
        </div>

        <div className="col-md-3 col-sm-6 mb-4">
          <div className="sector-card sector-6">
            <FaCogs className="sector-icon" />
            <Link className='text-decoration-none' to="solutions/systems"><h5 className='text-white'>Systèmes</h5></Link>
          </div>
        </div>

        <div className="col-md-3 col-sm-6 mb-4">
          <div className="sector-card sector-7">
            <FaUserFriends className="sector-icon" />
            <Link className='text-decoration-none' to="secteurs/services"><h5 className='text-white'>Services</h5></Link>
          </div>
        </div>

        

      </div>
    </div>
  );
};

export default Sectors;
