import Footer from "../../components/Footer";
import Header from "../../components/Header";
import SafariSecurite from "../../components/Structures/SafariSecurite";

function SecuritePage(){
    return(
        <div>
            < Header />
            < SafariSecurite />
            < Footer />
        </div>
    )
}


export default SecuritePage;