import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Industries from "../../components/Sectors/Industries";

function IndustriesPage(){
    return(
        <div>
            < Header />
            < Industries />
            < Footer />
        </div>
    )
}


export default IndustriesPage;