import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Services from "../../components/Sectors/Services";


function ServicesPage(){
    return(
        <div>
            < Header />
            < Services />
            < Footer />
        </div>
    )
}


export default ServicesPage;