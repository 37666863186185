import React from 'react';
import '../../assets/css/StructuresPages.css';
import CallToAction from '../CallToActions';

const SafariSecurite = () => {
  return (
    <div className="participations-container">
      <section className="hero-section-securite">
        <div className="hero-content">
          <h1 className="hero-title">Safari & Sécurité</h1>
          <p className="hero-subtitle">
            Protéger vos infrastructures numériques avec des solutions de sécurité avancées.
          </p>
        </div>
        <div className="hero-overlay"></div>
      </section>

      <section className="about-section">
        <h2 className="section-title">Qui Sommes-Nous ?</h2>
        <p className="about-text">
          <strong>Safari & Sécurité</strong> est spécialisé dans la protection des données et des systèmes numériques. Dans un monde où les menaces cybernétiques sont en constante évolution, nous offrons des solutions de sécurité adaptées pour protéger vos actifs les plus précieux.
        </p>
        <p className="about-text">
          Notre mission est simple : assurer la résilience de vos infrastructures, prévenir les attaques et garantir la continuité de vos opérations. Grâce à une expertise approfondie et des technologies de pointe, nous aidons nos clients à relever les défis de la cybersécurité avec confiance.
        </p>
      </section>

      <section className="orchestration-section">
        <h2 className="section-title">Votre Partenaire en Cybersécurité</h2>
        <p className="orchestration-text">
            Chez Safari & Sécurité, nous collaborons avec des prestataires spécialisés pour vous offrir des solutions de sécurité performantes et adaptées à vos besoins. Nous jouons un rôle clé dans la coordination et la réussite de vos projets numériques.
        </p>
        <div className="orchestration-grid">
            <div className="orchestration-item">
            <h3 className="orchestration-title">Coordination des Experts</h3>
            <p className="orchestration-description">
                Nous travaillons avec des prestataires qualifiés pour assurer la mise en place rapide et efficace de vos systèmes de sécurité.
            </p>
            </div>
            <div className="orchestration-item">
            <h3 className="orchestration-title">Solutions sur Mesure</h3>
            <p className="orchestration-description">
                En collaboration avec nos partenaires, nous adaptons chaque projet à vos enjeux spécifiques, garantissant des résultats optimaux.
            </p>
            </div>
            <div className="orchestration-item">
            <h3 className="orchestration-title">Support Permanent</h3>
            <p className="orchestration-description">
                Grâce à nos prestataires, bénéficiez d’un support 24/7 pour protéger vos systèmes et résoudre les incidents rapidement.
            </p>
            </div>
        </div>
        <div className="img-div mt-5">
            <img  className='w-100' height={600} src="https://img.freepik.com/free-photo/eye-futuristic-robot_53876-95096.jpg?t=st=1734100919~exp=1734104519~hmac=4437fcfee147263a6b8019b76e59a908721259fb356b9c1fee87fd91b2142045&w=1380" alt="Safari Participations Process"></img>
        </div>
        </section>


      <section class="circular-section">
        <h2 class="section-title">Pourquoi Choisir Safari & Sécurité ?</h2>
        <p class="circular-text">
            Nous vous proposons une approche innovante et complète pour sécuriser vos infrastructures numériques. Voici ce qui nous distingue :
        </p>
        <div class="circular-grid">
            <div class="circular-item">
            <div class="circle-icon">
                <i class="fas fa-shield-alt"></i>
            </div>
            <h3 class="circle-title">Technologies Avancées</h3>
            <p class="circle-description">
                Protégez vos données sensibles grâce à des outils de sécurité de pointe.
            </p>
            </div>
            <div class="circular-item">
            <div class="circle-icon">
                <i class="fas fa-clock"></i>
            </div>
            <h3 class="circle-title">Support Continu</h3>
            <p class="circle-description">
                Une assistance 24/7 pour surveiller et protéger vos infrastructures.
            </p>
            </div>
            <div class="circular-item">
            <div class="circle-icon">
                <i class="fas fa-user-shield"></i>
            </div>
            <h3 class="circle-title">Solutions Adaptées</h3>
            <p class="circle-description">
                Des solutions personnalisées pour répondre aux besoins uniques de chaque client.
            </p>
            </div>
            <div class="circular-item">
            <div class="circle-icon">
                <i class="fas fa-lock"></i>
            </div>
            <h3 class="circle-title">Prévention Active</h3>
            <p class="circle-description">
                Identifiez et neutralisez les menaces avant qu'elles n'affectent vos systèmes.
            </p>
            </div>
        </div>
        </section>


      <div className="bg-move contact-btn text-center mt-5">
        <CallToAction text="Protégez vos infrastructures dès aujourd'hui avec Safari & Sécurité." />
      </div>
    </div>
  );
};

export default SafariSecurite;
