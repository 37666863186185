import React from 'react';
import '../assets/css/CallToAction.css';
import { Link } from 'react-router-dom';



const CallToAction = ({text}) => {
  return (
    <div className="cta-container" id="join">
      <div className="cta-box" data-aos="fade-up">
        <p className="cta-text">
          {text}  
        </p>
        <button className="cta-button"><Link to="/contact" className='text-decoration-none text-dark'>Contactez-nous!</Link></button>
      </div>
    </div>
  );
};

export default CallToAction;
