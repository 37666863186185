import React from 'react';
import '../../assets/css/Choice.css';
import img from '../../assets/images/values.jpg'



const SafariValues = () => {
  return (
    <div>
      <h2 className="why-title text-center mb-5 mt-5" id="valeurs">
        NOS VALEURS
      </h2>
      <div className="safari-values-container">
        <div className="row align-items-center">
          <div className="col-md-6 position-relative">
            <img
              src={img}
              alt="Typing hands on keyboard"
              className="img-fluid rounded diagonal-img values-image diagonal-img"
            />
          </div>

          <div className="col-md-6">
            <div className="text-wrapper" >
              {values.map((value, index) => (
                <div
                  key={index}
                  className="value-item"
                  style={{
                    transform: 'translateX(-10%)',
                    top: `${-8 + index * 20}%`,
                    right: `${-3 + index * 5}%`,
                  }}
                  
                >
                  <div className="circle-container position-relative">
                    <div className="circle text-white d-flex align-items-center justify-content-center">
                      {value.letter}
                    </div>
                    <div className="text-container bg-beige" >
                      {value.title && <h5 className="mb-1 fw-bold">{value.title}</h5>}
                      {value.description && <p className="mb-0 oblique">{value.description}</p>}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};



const values = [
  {
    letter: 'S',
    title: 'SINCÉRITÉ',
    description:
      'Miser sur la sincérité permet de fluidifier la relation avec nos partenaires, nos collaborateurs et nos clients.',
  },
  {
    letter: 'A',
    title: 'AGILITÉ',
    description:
      'Attentif auprès des besoins de nos clients, nos collègues et nous-mêmes.',
  },
  {
    letter: 'F',
    title: 'FIABILITÉ',
    description: 'Dans nos engagements envers nos relations.',
  },
  {
    letter: 'A',
    title: 'ACTION',
    description: 'Écouter, Comprendre, passer à l’Action.',
  },
  {
    letter: 'R',
    title: 'RÉSULTAT',
    description:
      'Nos actions doivent contribuer à un résultat mesurable pour nos clients et nos collaborateurs.',
  },
  {
    letter: 'I',
    title: 'INNOVATION',
    description:
      'Le meilleur moyen de prédire l’avenir est de le prendre en main en développant nos propres idées.',
  },
];

export default SafariValues;
