import { useState } from 'react';
import React from 'react';
import '../assets/css/CeoMessage.css';
import ceoImage from '../assets/images/ceo.png'; 




const CeoMessage = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
    <div className="ceo-message-container">
      <div className="ceo-message-content">
        <div className="ceo-image">
          <img src={ceoImage} alt="CEO" />
        </div>
        <div className="ceo-text">
          <h2>Notre Président CEO</h2>
          <p><strong></strong></p>
        
          <p>
            Dans un monde en perpétuelle évolution, dans lequel les opportunités et les
            risques se croisent à chaque instant, il est important de tracer une route qui tire
            profit des progrès technologiques au service des hommes, des entreprises et de
            nos institutions.
            </p>

            {!isExpanded && (
            <p>
            Si nous naviguons/opérons dans un monde de nouvelles technologies, nous
            mettons au centre de notre
            préoccupations l’humain sans qui rien n’est possible. Une personne heureuse est
            aussi une personne efficace dans son métier.
            </p>
            )}

            {isExpanded && (
            <>
            <p>
            C’est avec cette ferme volonté, que nous avons développé au sein de Safari pour
            le bénéfice de nos clients et de nos collaborateurs les practices suivantes :
            </p>
            <ul>
                <li>Practice réseaux</li>
                <li>Practice Sécurité</li>
                <li>Practice Systèmes</li>
                <li>Practice application & ERP</li>
            </ul>
            <p>
            Sur ces 4 axes, nous sommes capables de vous apporter de la valeur ajoutée en prestations
            </p>

            <ul>
                <li> 
                    De support en Conception (Build) et en Production (RUN)
                </li>
                <li>
                    De déploiement
                </li>
                <li>D’infogérance</li>
                <li>D’AMOA</li>
            </ul>

            <p>
            Nos prestations sont souvent stratégiques pour nos clients, c’est la raison pour laquelle le processus de sélection de
            nos salariés est très pointu. Pour chaque recrutement, nous évaluons non seulement les savoirs faire par des tests
            techniques, mais aussi les savoirs être, l’adéquation Profil-Potentiel-Motivation.
            </p>
            
            <p>
            Cette démarche nous assure d’attirer vers nous des femmes et des hommes de qualité.
            </p>
            <p>
            Fidéliser nos consultants et développer leurs talents requiert toute notre attention. C’est la raison pour laquelle,
            nous avons mis en place
            </p>
            <span>
                <ul>
                    <li>Un management de proximité sur l’ensemble de nos prestations</li>
                    <li>Une politique de formation ambitieuse qui permette à nos consultants de profiter de leur potentiel.</li>
                    <li>Un espace collaboratif par practices de rattachement, permettant Know Edge sharing, best practices et support</li>
                </ul>
            </span>
            <p>
            Cet ensemble permet à notre groupe de délivrer des prestations dans un objectif d’excellence opérationnelle
            au service de nos clients.
            </p>
            </>
            )}

          <button className="btn btn-link mt-3" onClick={toggleExpand}>
            {isExpanded ? 'Lire moins' : 'Lire plus...'}
          </button>

          <div className="ceo-signature">
            <p>Nacer ACHERCHOUR</p>
            <span>Président | CEO chez SAFARI Technologies</span>
            <div className="ceo-linkedin">
              <a href="https://www.linkedin.com/in/acherchour/" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-linkedin"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CeoMessage;
