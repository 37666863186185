import Footer from "../../components/Footer";
import Header from "../../components/Header";
import SafariParticipations from "../../components/Structures/SafariParticipations";


function ParticipationsPage(){
    return(
        <div>
            < Header />
            < SafariParticipations />
            < Footer />
        </div>
    )
}


export default ParticipationsPage;