import React from 'react';
import '../../assets/css/StructuresPages.css';
import { FaCogs, FaCloud, FaShieldAlt } from 'react-icons/fa';
import CallToAction from '../CallToActions';
import { Link } from 'react-router-dom';

const SafariTechnologies = () => {
  return (
    <div className="participations-container">
      <section className="hero-section-technologies">
        <div className="hero-content">
          <h1 className="hero-title">Safari Technologies</h1>
          <p className="hero-subtitle">
            Fournir des solutions innovantes pour relever les défis de la transformation numérique.
          </p>
        </div>
        <div className="hero-overlay"></div>
      </section>

      <section className="about-section">
        <h2 className="section-title">Qui Sommes-Nous ?</h2>
        <p className="about-text">
          <strong>Safari Technologies</strong> est un acteur majeur de la transformation numérique, offrant des solutions technologiques innovantes pour répondre aux besoins variés de nos clients. Nous collaborons avec des entreprises de tous secteurs pour intégrer les outils numériques qui stimulent leur croissance et leur efficacité.
        </p>
        <p className="about-text">
          En mettant l’innovation au cœur de nos actions, nous aidons nos clients à tirer parti des dernières avancées technologiques pour rester compétitifs et atteindre leurs objectifs stratégiques.
        </p>
      </section>

      <section className="solutions-flow-section">
        <h2 className="section-title">Nos Solutions Technologiques</h2>
        <p className="flow-text">
          Nous vous accompagnons dans votre transformation numérique avec des solutions adaptées à chaque étape de votre parcours technologique.
        </p>
        <div className="flow-container">
          <Link to="/solutions/ITCloud" className="flow-step">
            <div className="flow-icon">
              <FaCloud />
            </div>
            <h3 className="flow-title">Cloud Computing</h3>
            <p className="flow-description">
              Déployez des infrastructures cloud fiables et sécurisées pour une meilleure agilité et scalabilité.
            </p>
          </Link>
          <div className="flow-arrow">→</div>
          <Link to="/solutions/reseau" className="flow-step">
            <div className="flow-icon">
              <FaCogs />
            </div>
            <h3 className="flow-title">Réseaux et Sécurité</h3>
            <p className="flow-description">
              Installez des réseaux robustes et sécurisés pour assurer la continuité de vos opérations et protéger vos données critiques.
            </p>
          </Link>
          <div className="flow-arrow">→</div>
          <Link to="/solutions/ApplicationsERP" className="flow-step">
            <div className="flow-icon">
              <FaShieldAlt />
            </div>
            <h3 className="flow-title">ERP Systems</h3>
            <p className="flow-description">
              Centralisez vos opérations et améliorez la gestion de vos ressources avec des systèmes ERP intégrés et personnalisés.
            </p>
          </Link>
        </div>

        <div className="img-div mt-5">
            <img  className='w-100' height={600} src="https://img.freepik.com/free-photo/global-business-internet-network-connection-iot-internet-things-business-intelligence-concept-busines-global-network-futuristic-technology-background-ai-generative_1258-176940.jpg?t=st=1734100574~exp=1734104174~hmac=a62cee03f06a9da93175798890c9ece826d2f1b2a97c6512bab616c6bbd60753&w=1380" alt="Safari Participations Process"></img>
        </div>      
        
        </section>

      <section className="commitment-section">
        <h2 className="section-title">Notre Engagement</h2>
        <p className="commitment-text">
            Chez Safari Technologies, nous plaçons l'innovation et la collaboration au cœur de notre approche pour garantir la réussite de nos clients. Notre engagement repose sur trois piliers fondamentaux :
        </p>
        <div className="commitment-pillars">
            <div className="pillar">
            <h3 className="pillar-title">Innovation</h3>
            <p className="pillar-text">
                Nous intégrons les dernières avancées technologiques pour concevoir des solutions modernes et performantes, adaptées à vos besoins.
            </p>
            </div>
            <div className="pillar">
            <h3 className="pillar-title">Collaboration</h3>
            <p className="pillar-text">
                En travaillant main dans la main avec nos clients, nous bâtissons des relations solides pour relever ensemble leurs défis technologiques.
            </p>
            </div>
            <div className="pillar">
            <h3 className="pillar-title">Excellence</h3>
            <p className="pillar-text">
                Nous nous engageons à fournir des services de haute qualité, avec un support technique constant et un souci du détail inégalé.
            </p>
            </div>
        </div>
        </section>

      <div className="bg-move contact-btn text-center mt-5">
        <CallToAction text="Contactez Safari Technologies pour des solutions numériques adaptées à vos objectifs." />
      </div>
    </div>
  );
};

export default SafariTechnologies;
