import React, {useEffect} from 'react';
import Header from '../components/Header';
import CarouselWithCards from '../components/Home/CarouselCards';
import Criteria from '../components/Home/Criteria';
import Structures from '../components/Home/Structures';
import Definition from '../components/Home/Definition';
import Sectors from '../components/Home/Sectors';
import CallToAction from '../components/CallToActions';
import Choice from '../components/Home/Choice';
import Footer from '../components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import  'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Swiper from '../components/Home/Swiper';
import { useLocation} from "react-router-dom"


function HomePage() {

    const location = useLocation();

    useEffect(() => {
        // Scroll to the element if there's a hash in the URL
        if (location.hash) {
        const id = location.hash.replace("#", ""); 
        const element = document.getElementById(id); 
        if (element) {
            element.scrollIntoView({ behavior: "smooth" }); 
        }
        }
    }, [location]);

    
  return (
    <div className="App">
      <Header />
      <CarouselWithCards />
      <Criteria />
      <Definition />
      <Structures />
      <Sectors />
      <Choice />
      <Swiper />
      <div className='cta-back'>
        <CallToAction text="Si vous cherchez une solution IT personnalisée qui répondra parfaitement à vos besoins d’entreprise, ne cherchez pas plus loin!"/>
      </div>
      <Footer />
    </div>
  );
}

export default HomePage;
